import {liteClient as algoliasearch, SearchResult} from 'algoliasearch/lite';

const algoliaConfig = window.CleverAge.algolia;

const searchClient = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);

/**
 * @param index
 * @param params
 * @return {Promise<SearchResult<unknown>>}
 */
export async function algoliaSearch(index, params) {
    const responses = await searchClient.search({
        requests: [
            {
                indexName: index,
                ...params,
            },
        ],
    });

    return responses.results[0];
}
