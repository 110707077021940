export class AgenciesMap {
    /** @type {HTMLElement} */
    #container;

    constructor(container) {
        this.#container = container;
        const focusableElems = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';
        const agencyMapSvg = this.#container.querySelector('.agency-marker-map');
        if (!agencyMapSvg) {
            return;
        }
        const agencyGhostList = this.#container.querySelector('.agencies-ghost-list');
        const markersSvgMap = agencyMapSvg.querySelectorAll('.agency-marker-map .agency-marker');
        const markers = this.#container.querySelectorAll('.agency-marker');
        const agenciesCaption = this.#container.querySelectorAll('.agency-caption');

        for (const agencyCaption of agenciesCaption) {
            if (!agencyCaption.classList.contains('active')) {
                agencyCaption
                    .querySelectorAll(focusableElems)
                    .forEach((elem) => elem.setAttribute('tabindex', '-1'));
            }
        }

        agencyGhostList.querySelectorAll('.agency-marker').forEach((agency) => {
            agency.addEventListener('focus', event => {
                let agencyId = event.target.closest('.agency').dataset.agencyId;

                for (let marker of markersSvgMap) {
                    marker.classList.remove('hover');
                }

                agencyMapSvg
                    .querySelector(`[data-agency-id="${agencyId}"] .agency-marker`)
                    .classList.add('hover');
            });
        });

        for (let marker of markers) {
            marker.addEventListener('click', () => {
                let agencyId = marker.closest('.agency').dataset.agencyId;
                agencyGhostList
                    .querySelectorAll('[data-agency-id]')
                    .forEach((agency) => {
                        agency.setAttribute('aria-current', 'false');
                    });
                agencyGhostList
                    .querySelector(`[data-agency-id="${agencyId}"]`)
                    .setAttribute('aria-current', 'true');

                for (let marker of markersSvgMap) {
                    marker.closest('.agency').classList.remove('active');
                }

                agencyMapSvg
                    .querySelector(`[data-agency-id="${agencyId}"]`)
                    .classList.add('active');

                for (let agencyCaption of agenciesCaption) {
                    agencyCaption.classList.remove('active');
                    agencyCaption
                        .querySelectorAll(focusableElems)
                        .forEach((elem) => {
                            elem.setAttribute('tabindex', '-1');
                        });
                }

                let currentAgencyCaption = this.#container.querySelector(
                    `[data-marker-id="${agencyId}"]`,
                );
                currentAgencyCaption.classList.add('active');
                currentAgencyCaption
                    .querySelectorAll(focusableElems)
                    .forEach((elem) => {
                        elem.setAttribute('tabindex', '0');
                    });
            });
        }
    }

}
