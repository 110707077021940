import {createGlobalState} from '@vueuse/core';
import {ref} from 'vue';
import {algoliaSearch} from '../algolia';

export const useSuggestionsStore = createGlobalState(() => {
    const suggestions = ref([]);

    async function fetchSuggestions(query, lang) {
        if (!query) {
            suggestions.value = [];
            return;
        }

        const {hits} = await algoliaSearch(window.CleverAge.algolia.indexName, {
            query,
            facetFilters: [
                `lang:${lang}`,
            ],
            hitsPerPage: 5,
        });
        suggestions.value = hits;
    }

    return {
        suggestions,
        fetchSuggestions,
    };
});
