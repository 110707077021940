<template>
    <span v-html="value" class="highlight-result"></span>
</template>

<script setup>
import {computed} from 'vue';

const {item, property} = defineProps({
    item: {
        type: Object,
        required: true,
    },
    property: {
        type: String,
        required: true,
    },
});

const value = computed(() => {
    return item._snippetResult[property]?.value || item._highlightResult[property]?.value || item[property];
});
</script>
