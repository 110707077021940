<template>
    <form role="search"
          method="get"
          class="search-form"
          :action="action"
          ref="form"
          @focusout="hideSuggestions($event)"
          @keydown.esc="hideSuggestions()">
        <div class="search-input">
            <label for="search-query" class="search-label">
                {{ __('Rechercher sur le site&hellip;', 'clever-age') }}
                <input type="search"
                       id="search-query"
                       class="search-field"
                       name="s"
                       :placeholder="__('Rechercher sur le site&hellip;', 'clever-age')"
                       autocomplete="off"
                       ref="input"
                       v-model="query"
                       @focus="showSuggestions()"/>
            </label>

            <button type="submit" class="search-submit">
                <span class="visually-hidden">{{ __('Rechercher', 'clever-age') }}</span>
                <svg focusable="false" aria-hidden="true" width="18" height="19">
                    <use :xlink:href="getThemeFileUri('build/sprite.svg#loupe')"></use>
                </svg>
            </button>
        </div>

        <div class="search-form-result"
             :style="{maxHeight: `${dropdownHeight}px`}"
             v-show="suggestionsVisible">
            <section class="search-form-suggestions"
                     v-for="(suggestions, typeLabel) in suggestionsByType"
                     :key="Math.random()">
                <header>
                    <h3 class="fw-700">{{ typeLabel }}</h3>
                </header>
                <ul>
                    <li v-for="suggestion in suggestions" :key="suggestion.id">
                        <a :href="suggestion.permalink">
                            <HighlightResult property="title" :item="suggestion"/>
                        </a>
                    </li>
                </ul>
            </section>

            <section class="search-form-categories">
                <header>
                    <span class="fw-700">{{ __('Rechercher dans les catégories', 'clever-age') }}</span>
                </header>
                <ul>
                    <li v-for="(attribute, value) in types">
                        <button type="submit" :name="attribute" :value="value">
                            {{ value }}
                        </button>
                    </li>
                </ul>
            </section>

        </div>
    </form>
</template>

<script setup>
import {onClickOutside, useElementBounding, useWindowSize} from '@vueuse/core';
import {computed, ref, watchEffect} from 'vue';
import {useScrollDirection} from '../composables/scrollDirection';
import {getThemeFileUri} from '../helpers';
import {useSearchStore} from '../stores/search';
import {useSuggestionsStore} from '../stores/suggestions';
import HighlightResult from './utils/HighlightResult.vue';

const {__} = wp.i18n;

const props = defineProps({
    postTypeLabels: Object,
    types: Object,
    action: String,
    isSearchResults: Boolean,
});

const {suggestions, fetchSuggestions} = useSuggestionsStore();
const {query} = useSearchStore();

const suggestionsVisible = ref(false);
const form = ref(null);
const input = ref(null);

const {height: windowHeight} = useWindowSize();
const {bottom: formBottom} = useElementBounding(form);
const scrollDirection = useScrollDirection();
const dropdownHeight = computed(() => Math.floor(windowHeight.value - formBottom.value));

onClickOutside(form, () => hideSuggestions());

const suggestionsByType = computed(() => {
    const suggestionsByType = {};
    suggestions.value.forEach(suggestion => {
        if (!suggestionsByType[suggestion.typeLabel]) {
            suggestionsByType[suggestion.typeLabel] = [];
        }
        suggestionsByType[suggestion.typeLabel].push(suggestion);
    });

    return suggestionsByType;
});

watchEffect(() => {
    if (scrollDirection.value === 'down') {
        hideSuggestions();
    }
    if (suggestionsVisible.value) {
        fetchSuggestions(query.value, window.CleverAge.lang);
    }
});

function showSuggestions() {
    if (props.isSearchResults) {
        return;
    }
    suggestionsVisible.value = true;
}

function hideSuggestions(event) {
    // If we click or tab to another eleement inside the component, do nothing
    if (event && (!event.relatedTarget || form.value.contains(event.relatedTarget))) {
        return;
    }
    suggestionsVisible.value = false;
    input.value.blur();
}
</script>
